.using-page
  h2
    font-size: larger
    font-family: montserrat, helvetica neue, Arial, sans-serif, -apple-system
    font-weight: 500
    color: $app-blue
    margin-top: 20px

  h3
    font-size: 1.2rem
    font-weight: 400
    color: $app-blue
    padding-bottom: 20px
    padding-top: 20px

  p
    font-size: 1.1rem
    padding-top: 5px
    padding-bottom: 5px
    text-align: justify

  ul
    list-style-type: disc
    padding-left: 20px
    padding-bottom: 5px
    ul
      list-style-type: circle
      padding-left: 20px

  code
    background-color: transparent

  .developing-alert
    color: $danger
    font-size: 1.2rem
    font-weight: 600
