.prng-main
  .random-numbers-inputs
    display: flex
    flex-direction: row
    align-items: center
    flex-wrap: wrap

    .n-input
      width: 33%

    .range-input
      width: 33%

    .control
      margin: 15px 5px

    @media screen and (max-width: 519px)
      .n-input
        width: 100%

      .range-input
        width: 50%
    
