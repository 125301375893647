.verification-detail-page
  .verification-title
    font-weight: 500 !important
    color: $app-blue
    font-size: 24px !important
    text-align: left !important
    margin: 30px 0 10px !important

  .verif-code
    text-align: center
    font-size: large
    font-weight: bolder

    code
      font-size: xx-large
      border-radius: 10px

  .verif-mode-title
    color: $app-blue

  @media screen and (max-width: 768px)
    .verif-code
      font-size: medium

      code
        font-size: large

  .verification-modes
    list-style-type: disc
    margin-left: 20px

  .code-fields
    margin-left: 20px

  .chainId-src
    color: white
    background-color: #ed342f

  .pulseId-src
    color: white
    background-color: #4c5cc4

  .round-src
    color: white
    background-color: #00b894