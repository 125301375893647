// Main Apps Page
.apps-main-page
  .external-apps-container
    margin-top: 20px
    background-color: rgba(58, 81, 187, .2)
    border-radius: 10px
    padding: 20px 10px

    h2
      color: $app-blue
      font-size: 1.5rem
      font-weight: 300
      margin: 0 0 20px 0
      text-align: center

  .accordions p
    text-align: center

  .accordion-header
    display: block !important
    cursor: pointer

    &:hover
      background-color: #3a51bb !important

  .app-desc
    align-content: center
    background-color: $app-blue
    color: white
    display: flex
    flex-direction: column
    font-size: medium
    justify-content: center
    left: 50%
    opacity: 0
    padding-left: 10px
    padding-right: 10px
    position: absolute
    top: 50%
    transform: translate(-50%, -50%)
    transition: opacity .2s, visibility .2s
    visibility: hidden

  .app-img
    border-style: solid
    border-width: 5px
    border-radius: 100px
    height: 200px
    width: 200px

  .app-item
    margin: 40px 40px 20px 40px
    position: relative
    text-align: center

    &:hover .app-desc
      opacity: 1
      visibility: visible

  .app-name
    color: $app-blue
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
    font-size: 1.4rem
    height: 20px
    margin: 0 0 40px 0
    text-align: center
    width: 280px

  .accordion-buttons
    display: flex
    justify-content: center
    align-items: center

    button
      margin: 0 10px

  .circular-size
    top: 100px
    border-radius: 95px
    height: 190px
    width: 190px

  .mobile-app
    display: none

    h2
      font-size: large
      font-weight: 300
      margin-bottom: 10px

  @media screen and (max-width: 640px)
    .mobile-app
      display: flex
      margin-bottom: 20px

    .desktop-app
      display: none

    .app-img
      border-style: solid
      border-width: 2px
      border-radius: 50px
      height: 100px
      width: 100px

    .mobile-app-desc
      max-width: 230px
      text-align: left
      padding-left: 20px

.app-page

  h1
    color: $app-blue
    text-align: center

  .app-button
    width: 48%
    margin: 10px 5px 0 5px

    @media screen and (max-width: 589px)
      margin: 5px 0
      width: 100%

  .breadcrumbs
    padding: 5px 20px

  .app-content
    
    .application
      padding: 0.75rem
      width: 66.6%

    .pulse-countdown
      padding: 0.75rem
      width: 33.3%
  
@media screen and (min-width: 769px)
  .app-page
    padding: 20px 0
    min-height: 500px

    .app-container
      background-color: white
      border-radius: 10px
      padding: 20px 10px

    .app-content
      padding: 10px
      display: flex

@media screen and (min-width: 769px) and (max-width: 920px)
  .app-page
    .pulse-countdown
      display: none

    .app-content
      .application
        width: 100%


@media screen and (max-width: 768px)
  .app-page

    .pulse-countdown
      display: none

    .app-container
      .breadcrumbs
        background-color: white

      .app-content
        .application
          width: 100%
          padding: 40px 10px
          border-radius: 0

      .app-container-light
        border: none
