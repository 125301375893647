.entry-date
  position: absolute
  float: right
  right: 60px

.entry-item
  background-color: hsla(229, 53%, 53%, 0.06)
  margin: 20px 0 20px !important
  border-radius: 20px
  padding: 20px

.loading-container
  img
    width: 50px

.entry-content
  p 
    font-size: 1.1rem
    padding-top: 5px
    padding-bottom: 5px
    text-align: justify

.entry-img
  width: 200px
  margin: auto
  overflow: hidden

  img
    border-radius: 10px
    width: auto
    max-height: 140px

.entry-link-button
  margin-top: 10px

  button
    width: 200px

.markdown-page h1
  color: $app-blue
  font-size: 28px

.markdown-entry-items
  h1
    color: $app-blue
    font-size: 28px

  h2
    font-weight: 500 !important
    color: $app-blue
    font-size: 24px !important
    text-align: left !important
    margin: 20px 0 10px !important

.markdown-page
  h2
    font-weight: 500 !important
    color: $app-blue
    font-size: 24px !important
    text-align: left !important
    margin: 50px 0 10px !important

  h3
    color: $app-blue
    font-size: 20px
    margin: 30px 0 10px !important

  h4
    color: $app-blue
    font-size: 18px
    margin: 20px 0 10px !important

  p
    text-align: justify
    margin: 10px 0 10px !important

  ul
    list-style: square !important
    padding-left: 20px !important

  ol
    list-style: decimal !important
    padding-left: 20px !important

.markdown-entry-items summary
  color: black
  cursor: pointer
  margin-left: 20px
