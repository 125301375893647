.modal-qr
  .modal-card-body
    text-align: center

  .modal-box
    background-color: white
    border-radius: 6px
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02)
    color: #4059c9
    display: block
    padding: 1.25rem
    width: 100%
    height: 100%

  .modal-content-qr
    display: flex
    justify-content: center
    align-items: center
    max-width: 100%
    flex-wrap: wrap

  .modal-description-qr
    padding: 0 20px 0 20px
    text-align: justify
    width: 100%
    min-width: 300px

  .modal-close
    background-color: rgba(0, 0, 0, 0.393) !important

  .modal-buttons
    text-align: center !important
    margin-top: 30px

  @media screen and (max-width: 640px)
    .modal-content
      position: fixed !important
      max-height: none !important
      margin: 0 10px 0 10px !important
      height: 100% !important

    .modal-box
      padding-top: 10%

    .modal-close
      position: absolute !important
      right: 10px
      top: 10px !important

    .modal-buttons button, .buttons-container button
      width: 85%