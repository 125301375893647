.textarea-error
  position: relative
  top: -1.25rem
  text-align: left

.num-input-error
  position: relative
  text-align: left

.textarea, .textarea:focus, .textarea:active, .textarea:hover
  border: 1px solid $app-blue
  border-radius: 5px
