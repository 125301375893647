.drand-title
  color: $app-blue
  font-size: 28px !important
  text-align: center

.drand-subtitle
  color: gray
  font-size: 22px !important
  text-align: center
  
.drand-content
  p, li
    line-height: 1.6em
    text-align: justify
    margin-top: 10px
    margin-bottom: 10px

  ul
    list-style: square !important
    padding-left: 20px !important

.drand-logo
  width: 60%
  max-width: 400px
  margin: auto

.whole-league-img
  max-width: 500px
  margin: auto

.drand-content
  h2
    font-size: 1.8rem
    font-weight: 400
    color: $app-blue
    margin-top: 40px

  h3
    font-size: 1.5rem
    font-weight: 400
    color: $app-blue
    padding-bottom: 20px
    padding-top: 20px

.drand-member-info
  overflow: auto

.drand-member
  float: left
  width: 100%

  img
    width: 30%
    min-width: 150px
    max-width: 200px
    height: fit-content