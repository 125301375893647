.about-page

  p
    font-size: 1.1rem
    padding-top: 5px
    padding-bottom: 5px
    text-align: justify

  .summary-container
    background-color: hsla(0, 0%, 0%, 0.047)
    border-radius: 10px
    color: $app-blue
    font-size: medium
    padding: 20px
    text-align: justify

  .summary-item
    width: 45%
    margin: 10px 0 10px
    min-height: 100px

  .summary-item-number
    font-family: montserrat, helvetica neue, Arial, sans-serif, -apple-system
    font-size: 2rem
    width: 40px

  .summary-image
    width: 100px

  .summary-text-container
    max-width: 300px
    padding-right: 20px
    padding-top: 5px
    padding-bottom: 5px
    text-align: justify

  @media screen and (max-width: 900px)
    .summary-item
      width: 100%

    .summary-text-container
      max-width: 350px
      padding-right: 20px

  // Colors

  .red-color
    color: #d76b51

  .yellow-color
    color: #ffd183

  .blue-color
    color: #79b6d2

  .cyan-color
    color: #7cd2ca

  .info-container
    h2
      font-size: larger
      font-family: montserrat, helvetica neue, Arial, sans-serif, -apple-system
      font-weight: 500
      color: $app-blue
      margin-bottom: 10px
      margin-top: 20px

    h3
      font-size: medium
      font-weight: 600

  .about-index
    margin-bottom: 10px
    margin-left: 20px

  .about-section
    margin-bottom: 20px
    margin-left: 20px
    margin-right: 20px

    p
      margin-bottom: 10px
      margin-top: 10px

  .similar-service-logo
    max-width: 200px
    padding-top: 15px
    max-height: 100px