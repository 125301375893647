$bingo-font: montserrat, helvetica neue, Arial, sans-serif, -apple-system
  
.bingo-app
  .nums-container
    display: flex
    align-items: center
    flex-direction: row
    flex-wrap: wrap

  .nums-by-char-container
    min-height: 50px

  .spell-container
    display: flex
    align-items: center
    flex-direction: row
    background-color: #485fc72f
    justify-content: left
    border-radius: 10px
    margin-bottom: 5px

  .newest-container
    height: 120px
    justify-content: center

  .old-nums-container
    &:empty
      display: none
    margin: 20px 0

  .spell-container .spelling-bingo
    height: 100%
    width: 45px
    font-size: x-large
    font-family: $bingo-font
    padding: 0 10px 0 15px

  @-webkit-keyframes bounce
    0%, 20%, 50%, 80%, 100%
      -webkit-transform: translateY(0)

    40%
      -webkit-transform: translateY(-30px)

    60%
      -webkit-transform: translateY(-15px)

  @keyframes bounce
    0%, 20%, 50%, 80%, 100%
      transform: translateY(0)

    40%
      transform: translateY(-30px)

    60%
      transform: translateY(-15px)

  .circled-number
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    background-color: white
    border: solid $app-blue 3px
    border-radius: 100%
    width: 50px
    height: 50px
    text-align: center
    margin: 5px

    &.is-active
      background-color: #00b894
      border-color: #00b894
      animation: bounce
      width: 100px
      height: 100px
      -webkit-animation-duration: 1s
      animation-duration: 1s
      -webkit-animation-fill-mode: both
      animation-fill-mode: both

      label
        color: white
        font-size: x-large

    label
      font-size: medium
      font-family: $bingo-font