$loading-color: #00b7ff
  
.dot
  position: absolute
  inset: 5px
  z-index: 10
  transform: rotate(calc(3.6deg * var(--percent)))

  &::before
    border-radius: 50%
    content: ''
    position: absolute
    width: 8px
    height: 8px
    background-color: $loading-color
    box-shadow: 0 0 10px $loading-color, 0 0 30px $loading-color
    left: 50%
    top: 1.5px

.loading
  inset: 0
  width: 40px
  height: 40px

.mini-loading
  inset: 0
  width: 15px
  height: 15px

.percent
  position: relative
  width: 100px
  height: 100px

  svg
    position: relative
    width: 100px
    height: 100px
    rotate: 270deg

    circle
      fill: transparent
      stroke: gray
      stroke-width: 3
      width: 100%
      height: 100%
      fill: transparent
      stroke: lightgray
      stroke-width: 3

      &:nth-child(2)
        stroke: $loading-color
        stroke-dasharray: 251px
        stroke-dashoffset: calc(251px - (251px * var(--percent) / 100))

.mini-percent
  position: relative
  width: 40px
  height: 40px

  svg
    position: relative
    width: 40px
    height: 40px
    rotate: 270deg

    circle
      fill: transparent
      stroke: gray
      stroke-width: 3
      width: 100%
      height: 100%
      fill: transparent
      stroke: lightgray
      stroke-width: 3

      &:nth-child(2)
        stroke: $loading-color
        stroke-dasharray: 95px
        stroke-dashoffset: calc(95px - (95px * var(--percent) / 100))

.time-left
  position: absolute
  inset: 0
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  animation: fadeIn 1s linear forwards

  h2
    font-weight: 700
    font-size: 2em
    line-height: 0%

  span
    font-weight: 200
    font-size: 0.5em

.mini-time-left
  h2
    font-weight: 600
    font-size: 1em
    line-height: 0%

.mini-progress-container
  position: absolute
  top: 40px
  right: 30px
  border: 1px solid $app-blue
  border-radius: 10px
  width: 160px
  display: flex
  justify-content: space-evenly
  align-items: center

@keyframes fadeIn
  0%
    opacity: 0

  100%
    opacity: 1