.footer
  color: white
  font-size: small
  display: flex
  flex-direction: row
  justify-content: center
  align-items: top
  flex-wrap: wrap

  a
    color: white

.footer-logo
  width: 22%
  margin: 20px 10px
  min-width: 240px
  text-align: center

.footer-logo-img
  height: 56px
  padding: var(--padding) 0
