.shuffle-app
  /* Margin for FA Icons
  .input-shuffle a svg
    margin: 0 10px 0 10px

  .file
    justify-content: center !important
    width: 100% !important

    .file-label
      width: 100% !important
      flex-wrap: wrap

    .file-cta
      padding: 1em 0.5em

    .file-name
      width: 100% !important
      max-width: 100% !important

  .shuffled-container
    background-color: #485fc72f
    margin: 1em
    border-radius: 6px
    display: block
    font-family: montserrat, helvetica neue, Arial, sans-serif, -apple-system
    margin: 1em 0
    padding: 1.25rem

  .keyboard-output
    max-height: 300px
    overflow-y: scroll
    overflow-x: scroll
    padding-left: 50px
    text-align: left
    font-size: 1.1em
    line-height: 1.2em

  .file-output
    text-align: center
    cursor: pointer

  .waiting-shuffle
    text-align: center

  .characters-limit
    text-align: right
    font-size: 0.8em
    color: #999

@media screen and ( max-width: 768px )
  .file-label
    white-space: normal