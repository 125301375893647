.external-icon
  color: white
  padding-left: 10px
  padding-right: 10px

.lang-toggle img
  cursor: pointer
  height: 16px
  margin-top: 5px

.social-items
  padding: 3px

.navbar-banner
  width: 100%
  height: auto

  p
    margin: 0
    padding: 4px
    font-weight: normal
    font-size: .95rem
    color: white

.nav-warning
  background-color: #fca503

.nav-success
  background-color: #48c774

.nav-error
  background-color: #f14668


// Show centered menu items 
@media screen and (max-width: 1023px)
  .field.is-grouped
    justify-content: center !important