.beacon-page

  p, li
    font-size: 1.1rem
    text-align: justify

  .beacon-description
    ul li
      margin: 15px 0 0 3%

    ol li
      margin: 5px 0 0 4%

    b
      color: $app-blue
  
  .beacon-tools
    list-style-type: none
    display: flex
    flex-wrap: wrap
    justify-content: space-around

    li
      border-radius: 10px
      padding: 10px
      background-color: hsla(229, 53%, 53%, 0.245)
      margin-left: 0px !important
      min-width: 400px
      width: 45%

      @media (max-width: 930px)
        width: 100%

      p
        margin: 5px 10px       

  .full-width
    width: 100%

  .light-bordered
    border-color: $app-blue
    border-radius: 20px
    border-style: solid
    border-width: 2px
    padding: 20px

  .notes-section
    background-color: hsla(229, 53%, 53%, 0.245)
    border-radius: 0 0 10px 10px
    color: gray
    font-size: small
    margin: 30px -50px -20px -50px
    padding: 20px
