.external-container
  width: 295px
  border-radius: 10px
  margin-top: 10px
  padding: 20px
  background-color: var(--color)
  cursor: pointer

  h3
    font-size: 1.2em
    color: white
    text-align: center
    font-weight: 600
    padding-bottom: 10px
    margin-bottom: 10px
    border-bottom: solid 1px

  p
    font-size: 0.9em
    text-align: justify
    color: white

.external-icon
  text-align: center

  img
    max-height: 100px
