@font-face
  font-family: ostrichSans
  src: url('../fonts/ostrich-regular.ttf')

.quote-container
  color: white
  display: flex
  flex: 1
  font-family: ostrichSans
  font-weight: 500
  line-height: 1.2em
  margin-top: 3vw
  font-size: 5em
  padding: 0 15px 0 15px

@media screen and (max-width: 770px)
  .quote-container
    font-size: 10vw
    padding: 0 10px 0 10px

  .logo-white
    display: none